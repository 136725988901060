export default (theme) => ({
  row: {
    marginTop: '65px',
    minHeight: 'calc(100vh - 65px)',
    flexDirection: 'column',
  },

  orderedList: {
    margin: '20px',
  },
});
