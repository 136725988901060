import axios from "axios";

export const LoadLogoAndBanner = (subdomain) => dispatch => {
  console.log("subdomain");
  dispatch({
    type: "LOAD_LOGO_AND_BANNER"
  })
  axios.get(`/get-subdomain/${subdomain}`)
    .then((response) => {

      dispatch({
        type: "UPDATE_LOGO_AND_BANNER",
        payload: response.data.data
      })

    })
    .catch((error) => {
      dispatch({
        type: "UPDATE_LOGO_AND_BANNER",
        payload: { id: 'false' }
      })
    })
  // return {
  //   type: "API",
  //   payload: {
  //     url: `/get-subdomain/${subdomain}`,
  //     methods: "GET",
  //     label: "LOAD_LOGO_AND_BANNER",
  //     onSuccess: (store, response) => {
  //       store.dispatch({
  //         type: "UPDATE_LOGO_AND_BANNER",
  //         payload: response.data,
  //       });
  //     },
  //     onFailure: (store) => {
  //       store.dispatch({
  //         type: "UPDATE_LOGO_AND_BANNER",
  //       });
  //     },
  //   },
  // };
};

export const socialMediaType = (data) => (dispatch) => {
  dispatch({
    type: "ACTIVE_SOCIAL_MEDIA_TYPE",
    payload: data,
  });
};
