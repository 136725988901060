const initState={
   totalFollowers:[],
   interactions:[],
   topPosts:[]
}

 const DashboardReducer=(state=initState,action)=>{
    return state;
}

export default DashboardReducer