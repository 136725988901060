import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const AdminRoutes = ({ component: Component, roles, ...rest }) => {
  const { isAuth, token, user } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) => {
        console.log(props);
        //token from state

        if (!isAuth) {
          // not logged in so redirect to login page with the return url
          return <Redirect to="/login" />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default AdminRoutes;
