export const FORM_ERROR_BRAND_NAME = " Brand Domain is Required";
export const FORM_ERROR_FIRST_NAME = " First name is required ";
export const FORM_ERROR_LAST_NAME = " Last name is required ";
export const FORM_ERROR_EMAIL = "Email  is required ";
export const FORM_ERROR_PASSWORD = "Password  is required ";
export const SUPER_ADMIN_NAME = "super-admin";
export const CUSTOMER_ADMIN_NAME = "customer-admin";
export const CUSTOMER_VIEWER_NAME = "customer-viewer";
export const ENTER_PAD = "Enter";
export const ENTER_NUM_PAD = "NumpadEnter";
export const PASSWORD_PATTERN = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
);
export const EMAIL_PATTERN = new RegExp(
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/
);
export const NO_DATA_AVAILABLE = "No data available"
