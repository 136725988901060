import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { isAuth, token, user } = useSelector((state) => state.auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        //token from state

        if (!isAuth) {
          // not logged in so redirect to login page with the return url

          return <Redirect to="/login" />;
        }

        if (isAuth && user && user.role === "super-admin") {
          return <Redirect to="/admin/dashboard" />;
        }

        // if (isAuth && user && user.role !== "super-admin") {
        //   return <Redirect to="/dashboard" />;
        // }
        

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
export default PrivateRoute;
