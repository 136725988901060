export const Styles = () => ({
    cardVideo: {
        "& #react-player": {
            "& div": {
                backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                borderRadius: 10,
              
            }
        }
      
    }
})