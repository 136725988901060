import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Divider, List, ListItem } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import renderHTML from 'react-render-html';
import Highlighter from "react-highlight-words";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  header: {
    padding: "20px 40px 20px 20px",
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
  },
}));

const concatHTML = (textArray, text = '') => {
  textArray.forEach(ta => {
    if (ta.props) {
      text = concatHTML(ta.props.children, text);
    } else {
      text = text + ta
    }
  })

  return text;
}

const getRenderedHtml = (html) => {
  let renderedText = '';

  if (!html === '' || html === '') return renderedText;

  const output = renderHTML(html);
  console.log({ output });

  const extractedOutput = output.length > 0 ? output[output.length - 1].props.children : output.props.children;
  console.log({ extractedOutput });

  return concatHTML(extractedOutput);
};

export default function HelpSectionList({ searchQuery, sectionList }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  console.log("section list", sectionList);

  useEffect(() => {
    if (sectionList.length !== 0) {
      setExpanded(sectionList[0].id)
    }
  }, [sectionList])

  const getRenderedHtml = (html) => {
    if (!html === '' || html === '') return '';
  
    const renderedHtml = renderHTML(html);
    console.log("render html value", renderedHtml)
    return renderedHtml.props.children[0];
  };

  return (
    <div className={classes.root}>
      {sectionList.map((section) => (
        <Accordion
          key={section.id}
          expanded={expanded === section.id}
          onChange={handleChange(section.id)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            className={classes.header}
            style={{
              backgroundColor: expanded === section.id ? "#FBE281" : "#fff",
            }}
          >
            <Typography
              style={{
                flexGrow: 1,
                fontSize: 18,
                color: expanded === section.id ? "#fff" : "#323132",
                fontWeight: 400,
              }}
            >
              {section.title}
            </Typography>
            {expanded === section.id ? (
              <RemoveIcon style={{ color: "#fff" }} />
            ) : (
              <AddIcon style={{ color: "#323132" }} />
            )}
          </AccordionSummary>
          <Divider />
          <List>
            <AccordionDetails>
              <ListItem style={{ cursor: "pointer" }}>
                <Typography>
                  {searchQuery ?
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      searchWords={[searchQuery]}
                      textToHighlight={getRenderedHtml(section.description)}
                    />
                    :
                    renderHTML(section.description)
                  }
                </Typography>
              </ListItem>
            </AccordionDetails>

          </List>
        </Accordion>
      ))}
    </div>
  );
}
