import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import store from "./store";
import { Provider } from "react-redux";
import { Router, Route, NavLink } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import 'react-dates/lib/css/_datepicker.css';

ReactDOM.render(
  <Provider store={store}>
    <Router history={createBrowserHistory()}>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);
