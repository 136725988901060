export default (theme) => ({
  row: {
    marginTop: "65px",
    minHeight: "calc(100vh - 65px)",
  },

  formContainer: {
    padding: "55px 30px",
    background: "#fff",
    height: "100%",
    minHeight: "calc(100vh - 65px)",
  },

  inputField: {
    width: "100%",
    borderRadius: 30, 
    // padding: "15px 14px",
  },  

  errorHelperText: {
    color: "#f44336"
  },

  userType: {
    color: "#f8c144",
    fontSize: 24,
  },
  userManagementForm: {
    // width: "calc(100vh - 60px)",
    marginTop: 20,
  },
  fileUploadConatiner: {
    display: "flex",
    margin: 0,
  },
  fileUploadbutton: {
    backgroundColor: "#FBE281",
  },
  radio: {
    "&$checked": {
      color: "#FBE281",
    },
  },
  checked: {},
  imgError: {
    color: " #f44336",
    fontSize: "0.75rem",
    marginLeft: "15px",
    marginTop: "5px",
  },
});
